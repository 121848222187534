const MathUtil = {
//除法
    div(arg1, arg2) {
        return +(arg1 / arg2).toFixed(4);
    },
// 乘法
    mul(arg1, arg2) {// 乘法运算
        if (!arg1) {
            arg1 = 0;
        }
        if (!arg2) {
            arg2 = 0;
        }
        let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) {
        }
        try {
            m += s2.split(".")[1].length
        } catch (e) {
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
// 加法
    plus(arg1, arg2) {// 加法运算
        let r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (this.mul(arg1, m) + this.mul(arg2, m)) / m
    },
// 减法
    minus(arg1, arg2) {// 减法运算
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        // 动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return +((this.mul(arg1, m) - this.mul(arg2, m)) / m).toFixed(n);
    },
};
export default MathUtil;