import React, {Fragment} from 'react';
import classnames from 'classnames'
import {connect} from "dva";
import QRCode from 'qrcode.react';
import {Popover, Input, Badge, message, Affix, Modal} from 'antd'
import {Scrollbars} from 'react-custom-scrollbars';
import qs from 'qs';
import './index.scss';
import ImgAdapt from "../img-adapt";
import StorageUtil from "../../utils/storage-util";
import {LocalConst} from "../../utils/constant";
import {SystemFetch} from "../../services/service";
import CommonUtil from "../../utils/common-util";
import RenderUtil from "../../utils/render-util";
import UrlUtil from "../../utils/url-config";
import SvgIcon from "../svg-icon";
import {injectIntl} from "react-intl";
import CheckUtil from "../../utils/check-util";
import userCenterImg from '../../assets/images/user-center.png';
import myMemberImg from '../../assets/images/my-member.png';
import memberBgImg from '../../assets/images/member-bg.png';
import logoutImg from '../../assets/images/logout.png';
import userMemberLovel01Img from '../../assets/images/user-member-lovel01.png';
import userMemberLovel02Img from '../../assets/images/user-member-lovel02.png';
import userMemberLovel03Img from '../../assets/images/user-member-lovel03.png';
import userMemberLovel04Img from '../../assets/images/user-member-lovel04.png';
import userMemberLovel05Img from '../../assets/images/user-member-lovel05.png';
import userMemberLovel06Img from '../../assets/images/user-member-lovel06.png';
import userMemberLovel07Img from '../../assets/images/user-member-lovel07.png';
import userMemberLovel08Img from '../../assets/images/user-member-lovel08.png';
import userMemberLovel09Img from '../../assets/images/user-member-lovel09.png';

import level01Img from '../../assets/images/level-01.png';
import level02Img from '../../assets/images/level-02.png';
import level03Img from '../../assets/images/level-03.png';
import level04Img from '../../assets/images/level-04.png';
import level05Img from '../../assets/images/level-05.png';
import level06Img from '../../assets/images/level-06.png';
import level07Img from '../../assets/images/level-07.png';
import level08Img from '../../assets/images/level-08.png';
import level09Img from '../../assets/images/level-09.png';

import level1UserIconImg from "../../assets/images/member/level1-user-icon.png";
import level2UserIconImg from "../../assets/images/member/level2-user-icon.png";
import level3UserIconImg from "../../assets/images/member/level3-user-icon.png";
import level4UserIconImg from "../../assets/images/member/level4-user-icon.png";
import level5UserIconImg from "../../assets/images/member/level5-user-icon.png";
import level6UserIconImg from "../../assets/images/member/level6-user-icon.png";
import level7UserIconImg from "../../assets/images/member/level7-user-icon.png";
import level8UserIconImg from "../../assets/images/member/level8-user-icon.png";
import level9UserIconImg from "../../assets/images/member/level9-user-icon.png";
import level10UserIconImg from "../../assets/images/member/level10-user-icon.png";

import level1IconImg from "../../assets/images/member/level1-icon.png";
import level2IconImg from "../../assets/images/member/level2-icon.png";
import level3IconImg from "../../assets/images/member/level3-icon.png";
import level4IconImg from "../../assets/images/member/level4-icon.png";
import level5IconImg from "../../assets/images/member/level5-icon.png";
import level6IconImg from "../../assets/images/member/level6-icon.png";
import level7IconImg from "../../assets/images/member/level7-icon.png";
import level8IconImg from "../../assets/images/member/level8-icon.png";
import level9IconImg from "../../assets/images/member/level9-icon.png";
import level10IconImg from "../../assets/images/member/level10-icon.png";


import IMGDownloadQrCode from '../../assets/images/app-download-qrcode.png';

const IMGLogo = "https://img.ysdinghuo.com/361@jyNZuh@shop-logo.png";

@injectIntl
@connect(state => ({...state.appModel}))
class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        const {history} = props;
        const query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
        this.state = {
            keywordList: [],
            keyword: query.keyword_ || '',
            classifyList: [],
            isFixationHead: false,
            letter: 'all',
            cartCount: StorageUtil.getItem(LocalConst.cartCount),
            ysClient: {},
            userLevelIconImg: '',
            levelIconImg: '',
            userData: {}
        };
        this.companyId = StorageUtil.getItem(LocalConst.companyId);
        this.companyData = StorageUtil.getItem(LocalConst.companyData);
        this.isLogin = CommonUtil.isLogin();
        this.user = StorageUtil.getItem(LocalConst.mShop_user);
        this.ysClient = StorageUtil.getItem(LocalConst.ysClient) || {};
        this.userMemeberList = StorageUtil.getItem(LocalConst.userMemeberList) || [];
        this.userLevelIconImgList = [
            level1UserIconImg,
            level2UserIconImg,
            level3UserIconImg,
            level4UserIconImg,
            level5UserIconImg,
            level6UserIconImg,
            level7UserIconImg,
            level8UserIconImg,
            level9UserIconImg,
            level10UserIconImg,
        ];

        this.levelIconImgList = [
            level1IconImg,
            level2IconImg,
            level3IconImg,
            level4IconImg,
            level5IconImg,
            level6IconImg,
            level7IconImg,
            level8IconImg,
            level9IconImg,
            level10IconImg,
        ];
    }

    componentDidMount() {
        this.searchClientKeyWordList();
        this.getClientInfoInterval = setInterval(() => {
            if (this.isLogin) {
                clearInterval(this.getClientInfoInterval);
                this.getClientInfo();
                this.getShopUserInfoForHome();
            }
        }, 1000);
        this.pageScrollBox = document.querySelector('body');
        this.pageScrollBox.addEventListener('scroll', () => this.handleScroll());
        // 更新购物车
        window.addEventListener('setItemEvent', (e) => {
            if (e.key === LocalConst.cartCount) {
                this.setState({
                    cartCount: JSON.parse(e.newValue)
                })
            }
        });

        document.addEventListener('click', e => {
            this.setState({
                isShowSearchDropdown: false
            })
        }, false)
    }

    getShopUserInfoForHome = () => {
        SystemFetch.getShopUserInfoForHome(null, rs => {
            this.setState({
                userData: rs.data
            })
        })
    };

    getClientInfo = () => {
        SystemFetch.getClientById(this.user.client_id_, rs => {
            if (rs.status === 1) {
                this.setState({
                    ysClient: rs.data || {},
                }, () => {
                    const {ysClient} = this.state;
                    let sum_score_ = ysClient.sum_score_ > 0 ? ysClient.sum_score_ : 0;
                    let userLevelIconImg = '';
                    let levelIconImg = '';
                    this.userMemeberList.forEach((item, index) => {
                        if (sum_score_ >= item.start_points_quota_ && sum_score_ <= item.ent_points_quota_) {
                            userLevelIconImg = this.userLevelIconImgList[item.id_ - 1];
                            levelIconImg = this.levelIconImgList[item.id_ - 1];
                        }
                    });
                    if (!userLevelIconImg) {
                        userLevelIconImg = this.userLevelIconImgList[9]; // 最高级会员
                        levelIconImg = this.levelIconImgList[9];
                    }
                    this.setState({
                        userLevelIconImg,
                        levelIconImg,
                    });
                });
            }
        })
    };

    handleScroll = () => {
        const {pageHeader = {}} = this.props;
        const {banner_ = {}} = pageHeader;
        const {height_ = 0} = banner_;
        this.setState({
            isFixationHead: this.pageScrollBox.scrollTop > (64 + height_)
        });
    };

    searchClientKeyWordList = () => {
        if (!this.isLogin) return;
        var param = {
            company_id_: this.companyId,
            showCount: 10,
        };
        SystemFetch.searchClientKeyWordList(param, (rs) => {
            if (rs.status === 1) {
                this.setState({
                    keywordList: rs.data.pdList || []
                })
            }
        });
    };

    searchKeyWordList = () => {
        const {keyword} = this.state;
        const param = {
            company_id_: this.companyId,
            keyword_: keyword,
            showCount: 10
        };
        SystemFetch.searchKeyWordList(param, (rs) => {
            if (rs.status === 1) {
                this.setState({
                    keywordList: rs.data.pdList || []
                })
            }
        });
    };

    selectKeywordLog = (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.searchKeyword(item.keywords_);
        this.setState({
            keyword: item.keywords_
        });


    };
    changeKeyword = (e) => {
        this.setState({
            keyword: e.target.value
        }, () => {
            const {keyword} = this.state;
            if (keyword.length > 0 && this.isLogin) {
                this.searchKeyWordList()
            } else {
                this.searchClientKeyWordList()
            }
        })
    };
    searchKeyword = (keyword) => {
        const {history} = this.props;
        let keyword_ = encodeURIComponent(keyword);
        const path = `/goods/list?keyword_=${keyword_}`;
        if (history.location.pathname === '/goods/lis') {
            history.replace(path);
        } else {
            history.push(path);
        }
    };
    // 删除客户指定的搜索关键词
    toDeleteKeywordsById = (e, id_) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        SystemFetch.toDeleteKeywordsById(id_, (rs) => {
            if (rs.status) {
                message.success(rs.message)
                this.searchClientKeyWordList()
            } else {
                message.error(rs.message)
            }
        });
    };
    // 删除客户所有的搜索关键词
    toDeleteClientAllKeywords = () => {
        SystemFetch.toDeleteClientAllKeywords(null, (rs) => {
            if (rs.status) {
                message.success(rs.message)
                this.searchClientKeyWordList()
            } else {
                message.error(rs.message)
            }
        });
    };


    getClassifyByDecorateName = (key, navigation) => {
        let name = '';
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    name = item.name_
                }
            });
        }
        return name
    };
    getClassifyByDecorateSeq = (key, navigation) => {
        let seq = '';
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    seq = item.seq_
                }
            });
        }

        return seq
    };
    getClassifyByDecorateHidden = (key, navigation) => {
        let hidden = 0;
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    hidden = item.hidden_
                }
            });
        }
        return hidden
    };
    handlePropertyByDecorateSort = (key, list, navigation) => {
        let propertyList = [];
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                list.forEach(l => {
                    if (item.classify_type_ === key && item.property_id_ === l.id_) {
                        l.type_ = 'property_' + l.id_;
                        l.hidden_ = item.hidden_;
                        l.seq_ = item.seq_;
                        l.children = item.children || [];
                        propertyList.push(l)
                    }
                })
            });
        }
        return propertyList
    };
    menuItemMouseEntre = (e) => {
        e.stopPropagation();
        const dataId = e.target.getAttribute('data-id');
        const selectItem = document.querySelector(`#jsSelect_${dataId}`);
        const menuActive = document.querySelectorAll(`.menu-active`);
        if (menuActive.length > 0) {
            menuActive.forEach(item => {
                const menuActiveId = document.querySelector(`#${item.id}`);
                if (`#jsSelect_${dataId}` !== `#${item.id}`) {
                    menuActiveId.classList.remove('menu-active')
                }
            })
        }
        if (selectItem) {
            selectItem.classList.add('menu-active')
        }
    };
    menuItemMouseLeave = () => {
        const menuActive = document.querySelectorAll(`.menu-active`);
        if (menuActive.length > 0) {
            menuActive.forEach(e => {
                const menuActiveId = document.querySelector(`#${e.id}`);
                menuActiveId.classList.remove('menu-active')
            })
        }
    };
    renderMenuItem = (sub, subIndex) => {
        return (
            <dl key={subIndex}>
                <dt onClick={() => this.selectQuery('type', sub)} role={'none'}>
                    {sub.name || sub.name_}
                </dt>
                {
                    (sub.children || []).map((i, sIndex) => {
                        return (
                            <dd key={sIndex} onClick={() => this.selectQuery('type', i)} role={'none'}>
                                {i.name}
                                {
                                    sIndex < sub.children.length - 1 &&
                                    <i className="short-line"/>
                                }
                            </dd>
                        )
                    })
                }
            </dl>
        )
    };
    selectLetter = (letter) => {
        this.setState({
            letter: letter
        })
    };
    renderMenuSubBoxList = (item, index) => {
        switch (item.type_) {
            case "type":
                let renderMenuItem = [];
                (item.children || []).forEach((sub, subIndex) => {
                    renderMenuItem.push(this.renderMenuItem(sub, subIndex))
                });
                return renderMenuItem || [];
            case "brand":
                const {letter} = this.state;
                return <div className='menu-brand-item-box' key={index}>
                    <div className="first-letter-box">
                        <span className={'all' === letter ? 'letter active' : 'letter'} role='none'
                              onClick={() => this.selectLetter('all')}>{this.props.intl.messages['QuanBuPinPai'] || '全部品牌'}</span>
                        {
                            item.keyArr.map(l => {
                                return (
                                    <span className={l === letter ? 'letter active' : 'letter'} role='none'
                                          onClick={() => this.selectLetter(l)} key={l}>{l}</span>
                                )
                            })
                        }
                    </div>
                    <div className="brand-item-box-main">
                        {
                            item.brandList[letter].map((brand, bIndex) => {
                                return (
                                    <div className="brand-box-card" key={bIndex}
                                         onClick={() => this.selectQuery(item.type_, brand)} role={'none'}>
                                        <div className="img-box">
                                            {
                                                brand.img_ ? (
                                                    <img src={brand.img_ + '_100'} alt=""/>
                                                ) : (
                                                    <span title={brand.name_}>{brand.name_}</span>
                                                )
                                            }
                                        </div>
                                        <div className="info-box">
                                            <div className="title-box">
                                                <p className="title ellipsis-2" title={brand.name_}>{brand.name_}</p>
                                                <p className="tips ellipsis-2"
                                                   title={brand.introduce_}>{brand.introduce_}</p>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                        }
                    </div>
                </div>
                    ;
            default:
                return (
                    <div className='nav-service-item-box' key={index}>
                        {
                            item.children.map((sub, subIndex) => {
                                return (
                                    <div className="item-box-card"
                                         key={subIndex}
                                         role={'none'}
                                         onClick={() => this.selectQuery(item.type_, sub)}
                                    >
                                        <span title={sub.name || sub.name_}>{sub.name || sub.name_}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
        }
    };
    handleClassifyList = (data, navigation) => {
        const {typeList, brandList, tagList, propertyList} = data;
        let results = [];
        if (typeList.length > 0) {
            let typeData = {};
            typeData.name = this.getClassifyByDecorateName(1, navigation);
            typeData.hidden_ = this.getClassifyByDecorateHidden(1, navigation);
            typeData.seq_ = this.getClassifyByDecorateSeq(1, navigation);

            typeData.children = typeList;
            typeData.type_ = 'type';
            typeData.id_ = typeData.type_;
            results.push(typeData)
        }
        if (brandList.length > 0) {
            let brandData = {};
            let brandList_ = {};
            let children = [];
            brandList.forEach(brand => {
                if (brand.is_view_shop_) {
                    children.push(brand);
                    if (!brandList_[brand.first_letter_]) {
                        brandList_[brand.first_letter_] = [];
                    }
                    brandList_[brand.first_letter_].push(brand)
                }
            });

            brandData.keyArr = Object.keys(brandList_).sort();
            brandData.brandList = {all: children, ...brandList_};
            brandData.name = this.getClassifyByDecorateName(3, navigation);
            brandData.hidden_ = this.getClassifyByDecorateHidden(3, navigation);
            brandData.seq_ = this.getClassifyByDecorateSeq(3, navigation);
            brandData.children = children;
            brandData.type_ = 'brand';
            brandData.id_ = brandData.type_;
            results.push(brandData)
        }
        if (tagList.length > 0) {
            let tagData = {};
            tagData.name = this.getClassifyByDecorateName(2, navigation);
            tagData.hidden_ = this.getClassifyByDecorateHidden(2, navigation);
            tagData.seq_ = this.getClassifyByDecorateSeq(2, navigation);

            tagData.children = tagList;
            tagData.type_ = 'tag';
            tagData.id_ = tagData.type_;
            results.push(tagData)
        }
        if (propertyList.length > 0 && (navigation && navigation.classify_list_ && navigation.classify_list_.find(item => item.classify_type_ === 4))) {
            let propertyDate = this.handlePropertyByDecorateSort(4, propertyList, navigation);
            results = results.concat(propertyDate)
        }
        let arr = [];
        let classifyList = [];
        results.forEach(item => {
            if (item && item.type_ === 'type') {
                classifyList = item.children
            }
            arr.push(item)
        });
        if (navigation && navigation.type_tree_ && navigation.type_tree_.type_) {
            classifyList = arr
        }

        classifyList = classifyList.sort((a, b) => {
            return a.seq_ - b.seq_
        });
        classifyList = classifyList.filter(item => {
            return !item.hidden_
        });
        return classifyList || []
    };
    navClick = (btn) => {
        const {history} = this.props;
        if (!btn.target_) {
            message.info('未知链接类型，无法跳转，请联系客服');
            return;
        }
        if (btn.target_.type_ === 8) {
            const newTab = window.open('about:blank');
            newTab.location.href = btn.target_.val_;
            return;
        }
        if (btn.target_.type_ === 33) {
            this.toBitMap();
            return;
        }
        if (!btn.newLocation) return;
        if (btn.newLocation.checkLogin) {
            RenderUtil.renderLoginModal.call(this.props.intl);
            return;
        }
        const path = `${btn.newLocation.pathname}?${qs.stringify(btn.newLocation.query)}`;
        history.push(path);
        this.setState({
            keyword: ''
        })
    };
    selectQuery = (type, item) => {
        const {history} = this.props;
        const query = {};
        switch (type) {
            case 'type':
                Object.assign(query, {type_: item.all_ids_});
                break;
            case 'tag':
                Object.assign(query, {tag_: item.id_});
                break;
            case 'brand':
                Object.assign(query, {brand_: item.id_});
                break;
            default: // 属性
                console.log('selectQuery', query);
        }
        // 关闭
        this.menuItemMouseLeave();
        this.setState({
            keyword: ''
        });
        const path = `/goods/list?${qs.stringify(query)}`;
        if (history.location.pathname === '/goods/list') {
            history.replace(path);
        } else {
            history.push(path);
        }
    };
    toPage = (pathname) => {
        if (!pathname) return;
        if (!this.isLogin) {
            this.toLogin();
            return;
        }
        const ysUser = StorageUtil.getItem(LocalConst.mShop_user) || {};
        if (pathname === '/user') {
            if (ysUser.client_id_ && !this.ysClient.distributor_parent_client_id_) {
                SystemFetch.getClientById(ysUser.client_id_, rs => {
                    if (rs.status === 1) {
                        if (rs.data.distributor_parent_client_id_) {
                            pathname = '/distributor/user';
                            this.props.history.push(pathname)
                        } else {
                            pathname = '/user';
                            this.props.history.push(pathname)
                        }
                    }
                })
            } else {
                if (this.ysClient.distributor_parent_client_id_) {
                    pathname = '/distributor/user';
                }
                this.props.history.push(pathname)
            }
        } else {
            this.props.history.push(pathname)
        }
    };
    toLogin = () => {
        const companyId = StorageUtil.getItem(LocalConst.companyId);
        window.location.href = `${UrlUtil.getAccountUrl(companyId)}/login`;
    };
    toLogout = () => {
        CommonUtil.logout()
        // CommonUtil.clearAccount();
        // this.props.history.push('/home')
    };
    //切换账号
    exchangeAccount = () => {
        SystemFetch.getAuthCode(null, rs => {
            if (rs.status !== 1) {
                message.error(rs.message);
                return;
            }
            if (this.isExperience) {
                // 体验账号切换到管理端
                CommonUtil.toMgrExperience();
            } else {
                const host = UrlUtil.getAccountUrl();
                window.location.href = `${host}/company?currentUser=${this.user.login_}&authCode=${rs.data}`
            }
        })

    };
    toLink = (target, name) => {
        if (typeof target === "undefined" || !target.type_) return;
        switch (target.type_) {
            case 8: //外部链接
                if (!CheckUtil.isUrl(target.val_)) return;
                window.location.href = `${target.val_}`;
                break;
            default:
                const newLocation = CommonUtil.getDecorateLocation(target);
                console.log('newLocation', newLocation)
                this.props.history.push({pathname: newLocation.pathname, search: qs.stringify(newLocation.query)});
        }
    };

    toMemberLevelDetail = () => {
        this.props.history.push('/memberLevel/detail');
    };

    toHome = () => {
        this.props.history.push('/user');
    };

    handleAffixItem = item => {
        const companyData = StorageUtil.getItem(LocalConst.companyData) || {};
        const {broadside_ = {}, intl} = this.props;
        switch (item.type_) {
            case 1:
                const url = `${UrlUtil.getMShopUrl()}/?companyId=${companyData.id_}`;
                return (
                    <div className="qrcode-box">
                        <div className="img-box">
                            <QRCode size={136} value={url}/>
                            <p className="txt">{intl.messages['WXSYS_DHGBJ'] || '微信扫一扫，订货更便捷'}</p>
                        </div>
                        {
                            companyData.wx_public_img_ &&
                            <div className="img-box">
                                <img src={companyData.wx_public_img_} alt=""/>
                                <p className="txt">{intl.messages['GZWXGZH'] || '关注微信公众号'}</p>
                            </div>
                        }
                    </div>
                );
            case 2:
                return (
                    <div className="service-box">
                        <dl>
                            <dt>{intl.messages['FuWuReXian'] || '服务热线'}</dt>
                            <dd>
                                <SvgIcon href="icon-service-phone"/>
                                <span className="tel">{broadside_.service_tel_}</span>
                            </dd>
                        </dl>
                        {
                            broadside_.service_list_ && broadside_.service_list_.length > 0 &&
                            <dl>
                                <dt>{intl.messages['ZaiXianKeFu'] || '在线客服'}</dt>
                                {
                                    (broadside_.service_list_ || []).map((service, index) => {
                                        return (
                                            <dd key={index}>
                                                <a className="service-QQ"
                                                   href={`http://wpa.qq.com/msgrd?v=3&uin=${service.service_qq_}&site=qq&menu=yes`}
                                                   target="_blank">
                                                    <SvgIcon href='icon-service-qq'/>
                                                    <span className="txt">{service.service_name_}</span>
                                                </a>
                                            </dd>
                                        )
                                    })
                                }

                            </dl>
                        }
                    </div>
                );
            default:
                return (
                    <div>{item.title_}</div>
                );
        }
    };

    affixOnClick = item => {
        switch (item.type_) {
            case 1:
            case 2:
                return;
            case 3:
                this.toPage('/order/list');
                break;
            case 4:
                document.body.scrollTop = 0;
                break
            // no default

        }
    };

    toggleSearchDropdown = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({
            isShowSearchDropdown: !this.state.isShowSearchDropdown
        })
    };

    isNavPage = btn => {
        const {history: {location}} = this.props;
        const {newLocation = {}} = btn;
        let isTrue = false;
        if (newLocation.pathname === '/goods/list' && location.pathname === '/goods/list') {
            const state = location.state || {};
            let query = Object.getOwnPropertyNames(newLocation.query);
            let state_ = Object.getOwnPropertyNames(state);

            if (state.from !== 'head' && Object.keys(newLocation.query).includes('companyId') && Object.keys(newLocation.query).length === 1) {
                isTrue = true;

            } else if (state.from === 'menu') {
                query = query.filter(item => item !== 'companyId');
                state_ = state_.filter(item => item !== 'companyId' && item !== 'from');
                for (let i = 0; i < query.length; i++) {
                    const key = query[i];
                    if (newLocation.query[key] === state_[key]) {
                        isTrue = false
                    }
                }
            } else {
                query = query.filter(item => item !== 'companyId');
                state_ = state_.filter(item => item !== 'companyId' && item !== 'from');
                if (query.length === 0 && state_.length === 0) {
                    isTrue = true
                }
                for (let i = 0; i < query.length; i++) {
                    const key = query[i];
                    if (newLocation.query[key] === state[key]) {
                        isTrue = true
                    }
                }
            }
        } else {
            isTrue = location.pathname === newLocation.pathname
        }


        return isTrue
    };
    toBitMap = () => {
        SystemFetch.getOpenAuthCode().then(rs => {
            let targetHost = UrlUtil.getBitMapUrl();// 跳转域名
            window.location.href = `${targetHost}?authCode=${rs.data}&companyId=${StorageUtil.getItem(LocalConst.companyId)}`;
        });

    };

    toBatchOrder = () => {
        if (this.isLogin) {
            this.props.history.push("/goods/batch/order");
        } else {
            RenderUtil.renderLoginModal.call(this.props.intl);
        }
    };

    getMemberLevelImg = () => {
        const {ysClient} = this.state;
        let memberLevelImg = '';
        if (ysClient.artificial_client_grade_ === 1) {
            memberLevelImg = userMemberLovel01Img;
        } else if (ysClient.artificial_client_grade_ === 2) {
            memberLevelImg = userMemberLovel02Img;
        } else if (ysClient.artificial_client_grade_ === 3) {
            memberLevelImg = userMemberLovel03Img;
        } else if (ysClient.artificial_client_grade_ === 4) {
            memberLevelImg = userMemberLovel04Img;
        } else if (ysClient.artificial_client_grade_ === 5) {
            memberLevelImg = userMemberLovel05Img;
        } else if (ysClient.artificial_client_grade_ === 6) {
            memberLevelImg = userMemberLovel06Img;
        } else if (ysClient.artificial_client_grade_ === 7) {
            memberLevelImg = userMemberLovel07Img;
        } else if (ysClient.artificial_client_grade_ === 8) {
            memberLevelImg = userMemberLovel08Img;
        } else if (ysClient.artificial_client_grade_ === 9) {
            memberLevelImg = userMemberLovel09Img;
        }
        return memberLevelImg;
    };

    getLevelImg = () => {
        const {ysClient} = this.state;
        let levelImg = '';
        if (ysClient.artificial_client_grade_ === 1) {
            levelImg = level01Img;
        } else if (ysClient.artificial_client_grade_ === 2) {
            levelImg = level02Img;
        } else if (ysClient.artificial_client_grade_ === 3) {
            levelImg = level03Img;
        } else if (ysClient.artificial_client_grade_ === 4) {
            levelImg = level04Img;
        } else if (ysClient.artificial_client_grade_ === 5) {
            levelImg = level05Img;
        } else if (ysClient.artificial_client_grade_ === 6) {
            levelImg = level06Img;
        } else if (ysClient.artificial_client_grade_ === 7) {
            levelImg = level07Img;
        } else if (ysClient.artificial_client_grade_ === 8) {
            levelImg = level08Img;
        } else if (ysClient.artificial_client_grade_ === 9) {
            levelImg = level09Img;
        }
        return levelImg;
    };

    getArtificialEndTime = () => {
        const {ysClient} = this.state;
        let artificial_yx_end_time = '';
        if (ysClient.artificial_yx_end_time) {
            artificial_yx_end_time = ysClient.artificial_yx_end_time.split(" ")[0];
        }
        return artificial_yx_end_time;
    };

    render() {
        const {
            ysClient,
            keywordList,
            keyword,
            isFixationHead,
            cartCount,
            isShowSearchDropdown,
            userLevelIconImg,
            levelIconImg,
            userData
        } = this.state;
        const {pageHeader = {}, decorateInfo = {}, broadside_ = {}, typeList, brandList, tagList, propertyList, history, intl} = this.props;
        const {logo_ = '', banner_ = {}, background_ = {}, navigation_ = {}} = pageHeader;
        const {is_working_ = null, company_wx_public_img_ = null} = decorateInfo;
        console.log('userData：', userData);
        const backgroundStyle = {
            'backgroundColor': background_.color_,
            'backgroundPositionX': background_.horizontal_,
            'backgroundPositionY': background_.vertical_,
            'backgroundRepeat': background_.repeat_ === 'padding' ? 'no-repeat' : background_.repeat_,
            'backgroundSize': background_.repeat_ === 'padding' ? '100% 100%' : '',
        };
        if (background_.img_) {
            backgroundStyle['backgroundImage'] = 'url(' + background_.img_ + ')'
        }
        const headerBannerStyle = {
            'height': (banner_.height_ || 0) + 'px',
        };
        const classifyStyle = {
            'height': navigation_.type_tree_ && navigation_.type_tree_.height_ + 'px',
            'backgroundColor': navigation_.background_color_ ? CommonUtil.colorRgb(navigation_.background_color_, navigation_.background_alpha_) : ''
        };
        const classifyFontStyle = {
            'color': navigation_.font_color_ ? CommonUtil.colorRgb(navigation_.font_color_, navigation_.font_alpha_) : '',
            'borderColor': navigation_.font_color_ ? CommonUtil.colorRgb(navigation_.font_color_, 10) : '',
        };

        const pathname = window.location.pathname;
        const companyName = StorageUtil.getItem(LocalConst.companyName) || '';
        const companyDate = StorageUtil.getItem(LocalConst.companyData) || {};
        const overlayItem = <div className='search-log-list-box'>
            <ul className="log-box">
                {
                    (keywordList || []).map((item, index) => {
                        return (
                            <li className='log-item' key={index}>
                                <span className='txt ellipsis' role='none' title={item.keywords_}
                                      onClick={(e) => this.selectKeywordLog(e, item)}>{item.keywords_}</span>
                                <span className='ope'>
                                    <span
                                        className='tips'>{item.search_count_ > 0 ?
                                        intl.formatMessage({
                                            id: 'Yue1GeShangPin'
                                        }, {num: item.search_result_})
                                        // '约' + item.search_result_ + '个商品'
                                        : (intl.messages['SouSuoLiShi'] || '搜索历史')}
                                    </span>
                                    {
                                        this.user && this.user.id_ && !keyword &&
                                        <span className='del' role="none"
                                              onClick={(e) => this.toDeleteKeywordsById(e, item.id_)}>
											{intl.messages['ShanChu'] || '删除'}

										</span>
                                    }
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
            {
                this.user && this.user.id_ && !keyword &&
                <div className='keyword-foot-box'>
                    <span className='txt' role="none"
                          onClick={this.toDeleteClientAllKeywords}>{intl.messages['QuanBuShanChu'] || '全部删除'}</span>
                </div>
            }
        </div>;

        const classifyList = this.handleClassifyList(
            {
                typeList: typeList,
                brandList: brandList,
                tagList: tagList,
                propertyList: propertyList
            },
            navigation_
        );
        // 头部搜索栏
        const pageHeadMain = <div className="main-wrap">
            <div className="logo-box cur" role="none" onClick={() => this.toLink({type_: -1})}>
                <img src={"https://img.milanhome.shop/Fkzs3WR02pwMi7WLf3T9OAJXgs78"} alt=""/>
            </div>
            <div className="search-wrap">
                <div className="search-box" role="none" onClick={(e) => this.toggleSearchDropdown(e)}>
                    <Input type="text"
                           className="txt"
                           placeholder={intl.messages['SPMC_GG_BH_'] || '请输入商品名称/规格/编号/首字母'}
                           value={keyword}
                           onPressEnter={() => this.searchKeyword(keyword)}
                           onChange={this.changeKeyword}/>
                    <div className="search-btn" onClick={() => this.searchKeyword(keyword)} role="none"><i
                        className='iconfont icon-sousuo'/></div>
                    {
                        isShowSearchDropdown && keywordList.length > 0 &&
                        <div className="dropdown-box">{overlayItem}</div>
                    }
                </div>
                <Badge count={cartCount} className="cart-box"
                       onClick={() => history.push("/cart")}>
                    <i className='iconfont icon-shopping_cart_s'/>{intl.messages['GouWuChe'] || '购物车'}
                </Badge>
                <Badge className="cart-box"
                       onClick={this.toBatchOrder}>
                    批量下单
                </Badge>
            </div>

        </div>;
        return (
            <Fragment>
                <div className={`page-head-wrap page-head-wrap-fix ${isFixationHead ? 'opacity-1' : ``}`}
                     style={backgroundStyle}>
                    <div className="head-main-box">
                        <div className="page-wrap">{pageHeadMain}</div>
                    </div>
                </div>
                <div className={`page-head-wrap ${isFixationHead ? 'opacity-0' : ``}`} style={backgroundStyle}>
                    {
                        banner_.show_ &&
                        is_working_ &&
                        (pathname.indexOf('/home') > -1 || pathname === '/') ?
                            <div className="header-banner-box" style={headerBannerStyle}>
                                <ImgAdapt src={banner_.img_} alt=""/>
                            </div> : null
                    }
                    <div className="shop-head-user-box">
                        <div className="main-box">
                            <div className="head-welcome-txt">{intl.formatMessage({
                                id: 'NH_HYLDYSDH'
                            }, {company: companyName})}
                                {/*`您好，欢迎来到${companyName}商城！`*/}
                            </div>
                            <div className="head-user-nav-box">
                                <Popover
                                    placement="bottom"
                                    trigger="hover"
                                    content={
                                        <div className="nav-card-main">
                                            <div className="qr-code-box">
                                                <div className="code-box">
                                                    <img src={companyDate.android_url_ || IMGDownloadQrCode} alt=""/>
                                                </div>
                                                <p>Android</p>
                                            </div>
                                            <div className="qr-code-box">
                                                <div className="code-box">
                                                    <img src={companyDate.ios_url_ || IMGDownloadQrCode} alt=""/>
                                                </div>
                                                <p>IOS</p>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="nav-card">
                                        <p>{intl.messages['ZSJDH'] || '在手机订货'}</p>
                                    </div>
                                </Popover>
                                {
                                    company_wx_public_img_ &&
                                    <Popover
                                        placement="bottom"
                                        trigger="hover"
                                        content={
                                            <div className="nav-card-main">
                                                <div className="qr-code-box">
                                                    <div className="code-box"><img src={company_wx_public_img_} alt=""/>
                                                    </div>
                                                    <p>{intl.messages['SYS_GZWXGZH'] || '扫一扫，关注微信公众号'}</p>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="nav-card">
                                            <p>{intl.messages['GuanZhuWoMen'] || '关注我们'}</p>
                                        </div>
                                    </Popover>
                                }

                                <div className="nav-card">
                                    <p className="user-nav-card" role="none"
                                       onClick={() => this.toPage('/collect/list')}>{intl.messages['WoDeShouCang'] || '我的收藏'}</p>
                                </div>
                                {
                                    ysClient.is_enable_grade_ ? (
                                        <Popover
                                            placement="bottom"
                                            trigger="hover"
                                            content={
                                                <div className="user-member-nav-card-box" style={{width: '172px'}}>
                                                    <div className="user-info">
                                                        <div className="user-name">{userData.name_}</div>
                                                        <div className="level-icon-box">
                                                            <img src={levelIconImg} alt=""/>
                                                            <div className='level-icon'>LV{ysClient.artificial_client_grade_}</div>
                                                        </div>
                                                    </div>
                                                    <div className="member-bg-img-box">
                                                        <img src={userLevelIconImg} alt=""/>
                                                        <div className="member-id">NO.{ysClient.code_ex_}</div>
                                                    </div>
                                                    <div className="my-member-img-box cur" onClick={this.toMemberLevelDetail} role={'none'}>
                                                        <img src={myMemberImg} alt=""/>
                                                    </div>
                                                    <div className="user-center-img-box cur" onClick={this.toHome} role={'none'}>
                                                        <img src={userCenterImg} alt=""/>
                                                    </div>
                                                    <div className="logout-img-box cur"  onClick={this.toLogout} role={'none'}>
                                                        <img src={logoutImg} alt=""/>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className="nav-card">
                                                <p>{intl.messages['GeRenZhongXin'] || '个人中心'}</p>
                                            </div>
                                        </Popover>
                                    ) : (
                                        <Popover
                                            placement="bottom"
                                            trigger="hover"
                                            content={
                                                <div className="user-nav-card-box">
                                                    <div className="user-nav-card" role="none"
                                                         onClick={() => this.toPage('/user')}>
                                                        {intl.messages['WoDeZhangHao'] || '我的账号'}
                                                    </div>
                                                    {/*{*/}
                                                        {/*this.isLogin && this.user && this.user.bind_phone_ &&*/}
                                                        {/*<div className="user-nav-card" role="none"*/}
                                                             {/*onClick={this.exchangeAccount}>*/}
                                                            {/*{intl.messages['QieHuanZhangHao'] || '切换账号'}*/}
                                                        {/*</div>*/}
                                                    {/*}*/}
                                                    <div className="user-nav-card" role="none"
                                                         onClick={() => this.toPage('/keep/list')}>
                                                        {intl.messages['GouMaiJiLu'] || '购买记录'}
                                                    </div>
                                                    {
                                                        this.isLogin &&
                                                        <div className="user-nav-card" role="none"
                                                             onClick={this.toLogout}>
                                                            {intl.messages['TuiChuDengLu'] || '退出登录'}
                                                        </div>
                                                    }
                                                    {
                                                        !this.isLogin &&
                                                        <div className="user-nav-card" role="none"
                                                             onClick={this.toLogin}>{intl.messages['QuDengLu'] || '去登录'}</div>
                                                    }
                                                </div>
                                            }
                                        >
                                            <div className="nav-card">
                                                <p>{intl.messages['GeRenZhongXin'] || '个人中心'}</p>
                                            </div>
                                        </Popover>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="head-main-box">
                        {
                            !background_.is_hide_border_ &&
                            <div className="line-wrap"/>
                        }
                        <div className="page-wrap">
                            {pageHeadMain}
                            <div className="nav-wrap">
                                {
                                    !(navigation_.classify_title_ && navigation_.classify_title_.hidden_) &&
                                    <div className="menu-wrap">
                                        {
                                            !navigation_.classify_title_ &&
                                            <div className="menu-title">
                                                {intl.messages['QBSPFL'] || '全部商品分类'} <i
                                                className="icon-fenlei iconfont"/>
                                            </div>
                                        }
                                        {
                                            navigation_.classify_title_ &&
                                            !navigation_.classify_title_.hidden_ &&
                                            <div className="menu-title">
                                                {navigation_.classify_title_.name_}
                                                <i className="icon-fenlei iconfont"/>
                                            </div>
                                        }
                                        <div
                                            className={navigation_.type_tree_ && navigation_.type_tree_.show_ && (pathname === '/' || pathname.indexOf('/home') > -1) ? "menu-box dis-show" : "menu-box"}
                                            onMouseLeave={this.menuItemMouseLeave}
                                            ref='jsMenuBox'>
                                            <Scrollbars className="menu-main-box" style={classifyStyle}>
                                                {
                                                    (classifyList || []).map((item, index) => {
                                                        return (
                                                            <span
                                                                role={'none'}
                                                                className="menu-item"
                                                                data-id={item.id_}
                                                                onMouseEnter={this.menuItemMouseEntre}
                                                                style={classifyFontStyle}
                                                                key={index}
                                                                onClick={() => this.selectQuery(item.type_ || 'type', item)}
                                                            >
                                                                {item.name}
                                                                {
                                                                    item.children && item.children.length > 0 &&
                                                                    <i className="goto iconfont icon-jump"
                                                                       style={classifyFontStyle}/>
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </Scrollbars>
                                            {
                                                classifyList.filter(item => {
                                                    return !item.hidden_ && item.children && item.children.length > 0
                                                }).map((item, index) => {
                                                    return (
                                                        <div className="menu-sub-box" id={`jsSelect_${item.id_}`}
                                                             key={index}>
                                                            {
                                                                !(navigation_.type_tree_ && navigation_.type_tree_.type_) ? (
                                                                    item.children.map((sub, subIndex) => {
                                                                        return this.renderMenuItem(sub, subIndex)
                                                                    })
                                                                ) : (
                                                                    this.renderMenuSubBoxList(item, index)
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="nav-box">
                                    <Fragment>
                                        {
                                            (navigation_.buttons_ || []).filter(item => {
                                                return !item.hidden_
                                            }).map((btn, index) => {
                                                const cls = classnames('nav-item', {
                                                    'active': this.isNavPage(btn)
                                                });
                                                return (
                                                    <span className={cls}
                                                          role={'none'}
                                                          onClick={() => this.navClick(btn)}
                                                          key={index}>{btn.name_}</span>
                                                )
                                            })
                                        }
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Affix className="affix-box">
                    <section>
                        {
                            (broadside_.fixbar_list_ || []).filter(item => !item.hidden_).map((item, index) => {
                                return (
                                    <Popover placement="leftTop" content={this.handleAffixItem(item)}
                                             getPopupContainer={() => this.affix} trigger="hover" key={index}>
                                        <div className="affix-item" ref={ref => this.affix = ref} role="none"
                                             onClick={() => this.affixOnClick(item)}>
                                            <i className={`iconfont ${item.icon_}`}/>
                                        </div>
                                    </Popover>
                                )
                            })
                        }
                    </section>
                </Affix>
            </Fragment>
        )
    }
}

export default PageHeader
